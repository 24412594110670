"use client";
import ErrorMessage from "@/components/shared/ErrorMessage";
import { useEffect } from "react";

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};
function GlobalError({ error, reset }: Props) {
  useEffect(() => {
    console.log("error of layout: ", error);
  }, [error]);

  return (
    <html>
      <body>
        <ErrorMessage message={"هناك عطل في الموقع ونعمل حاليا علي إصلاحه"} />
      </body>
    </html>
  );
}

export default GlobalError;
