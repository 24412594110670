import React from "react";
import Link from "next/link";
import MyLink from "./myLink/MyLink";

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <p className="text-2xl text-red-500 font-bold mb-4">!يوجد خطأ</p>
      <p className="text-lg text-white mb-4">{message}</p>
      <MyLink
        href="/"
        className="text-lg text-primaryColor underline hover:brightness-110"
      >
        العودة إلى الصفحة الرئيسية
      </MyLink>
    </div>
  );
};

export default ErrorMessage;
